const PhoneIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 58 58"
        >
            <path
                fill="#adb5bd"
                d="M2.874 7.185v5.767c0 23.015 18.658 41.673 41.673 41.673h5.748c2.38 0 4.311-1.93 4.311-4.311v-7.372a4.311 4.311 0 00-2.948-4.09l-9.148-3.05a4.31 4.31 0 00-4.95 1.699l-2.096 3.143a4.31 4.31 0 01-4.95 1.698l-4.384-1.461a14.173 14.173 0 01-9.192-9.717l-1.802-6.608a4.311 4.311 0 012.796-5.224l1.732-.577a3.881 3.881 0 002.6-4.32l-1.326-7.959a4.311 4.311 0 00-4.252-3.602H7.185a4.311 4.311 0 00-4.311 4.311zM0 7.185A7.185 7.185 0 017.185 0h9.5c3.513 0 6.51 2.54 7.088 6.004l1.326 7.958a6.755 6.755 0 01-4.527 7.52l-1.731.576a1.437 1.437 0 00-.932 1.742l1.802 6.608a11.3 11.3 0 007.328 7.746l4.384 1.462a1.437 1.437 0 001.65-.567l2.095-3.142a7.185 7.185 0 018.25-2.83l9.15 3.049a7.185 7.185 0 014.912 6.816v7.372a7.185 7.185 0 01-7.185 7.185h-5.748C19.944 57.499 0 37.554 0 12.952V7.185z"
            ></path>
        </svg>
    );
}

export default PhoneIcon;