const LoginIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 58 58"
        >
            <path
                fill="#000"
                d="M46.703 53.888H25.146a3.593 3.593 0 01-3.592-3.593v-5.389h3.593v5.389h21.555V7.185H25.146v5.389h-3.592V7.185a3.593 3.593 0 013.593-3.592h21.555a3.593 3.593 0 013.592 3.592v43.11a3.593 3.593 0 01-3.593 3.593z"
            ></path>
            <path
                fill="#000"
                d="M26.207 36.985l6.43-6.449H7.186v-3.592h25.453l-6.43-6.449 2.532-2.533L39.517 28.74 28.74 39.517l-2.533-2.532z"
            ></path>
        </svg>
    );
}

export default LoginIcon;