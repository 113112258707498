const EmailIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 58 58"
        >
            <path
                fill="#adb5bd"
                d="M51.093 9.58H6.387a3.193 3.193 0 00-3.194 3.193v31.934A3.193 3.193 0 006.387 47.9h44.706a3.194 3.194 0 003.194-3.193V12.773a3.194 3.194 0 00-3.194-3.193zm-2.459 35.127H9.038l11.177-11.56-2.3-2.22L6.388 42.855V15.2l19.846 19.751a3.193 3.193 0 004.503 0l20.357-20.246v27.942L39.342 30.895l-2.251 2.252 11.544 11.56zM8.479 12.773h40.029l-20.022 19.91-20.007-19.91z"
            ></path>
        </svg>
    );
}

export default EmailIcon;