const HistoryIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 58 58"
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M30.6 3.66a25.143 25.143 0 00-1.85-.068V0c.705 0 1.411.027 2.115.079l-.266 3.581zm7.198 1.617a25.158 25.158 0 00-3.538-1.074l.786-3.506c1.376.309 2.73.718 4.045 1.228l-1.293 3.352zm4.921 2.55a25.169 25.169 0 00-1.577-.97l1.771-3.125a28.822 28.822 0 013.517 2.35l-2.209 2.834a25.12 25.12 0 00-1.502-1.085v-.003zm6.589 6.43a25.11 25.11 0 00-2.346-2.859l2.6-2.478a29.971 29.971 0 012.684 3.268l-2.938 2.07zm2.672 4.857a25.39 25.39 0 00-.768-1.68l3.207-1.617a28.65 28.65 0 011.617 3.908l-3.413 1.124a25.282 25.282 0 00-.643-1.735zm1.904 9.006a25.12 25.12 0 00-.36-3.682l3.54-.61c.24 1.386.38 2.794.416 4.202l-3.592.09h-.004zm-.47 5.525c.118-.61.215-1.218.29-1.832l3.568.442a28.58 28.58 0 01-.827 4.149l-3.462-.96a25.28 25.28 0 00.43-1.799zm-3.42 8.546a25.364 25.364 0 001.746-3.262l3.283 1.455a28.988 28.988 0 01-1.994 3.729l-3.035-1.922zM46.53 46.52c.438-.439.858-.891 1.257-1.358l2.723 2.346a29.018 29.018 0 01-1.44 1.551l-2.54-2.54z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M28.75 3.592A25.146 25.146 0 1046.53 46.52l2.54 2.54A28.741 28.741 0 1128.75 0v3.592z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M26.953 10.777a1.796 1.796 0 011.796 1.796v18.715l11.668 6.668a1.796 1.796 0 01-1.782 3.118l-12.573-7.185a1.798 1.798 0 01-.905-1.559V12.573a1.796 1.796 0 011.796-1.796z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default HistoryIcon;