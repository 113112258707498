import React from 'react'

const TwitterIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 33 33"
            style={{ cursor: "pointer" }}
        >
            <path
                fill="#00AAEC"
                fillRule="evenodd"
                d="M33 6.006a13.223 13.223 0 01-3.887 1.092 6.927 6.927 0 002.977-3.84 13.335 13.335 0 01-4.302 1.683 6.677 6.677 0 00-4.942-2.191c-3.736 0-6.767 3.109-6.767 6.942 0 .544.058 1.073.174 1.581-5.627-.29-10.615-3.05-13.956-7.256a7.069 7.069 0 00-.916 3.494 6.988 6.988 0 003.012 5.778 6.648 6.648 0 01-3.068-.866v.086c0 3.364 2.334 6.17 5.433 6.806a6.455 6.455 0 01-1.784.245 6.58 6.58 0 01-1.274-.123c.862 2.757 3.36 4.765 6.324 4.818a13.369 13.369 0 01-8.409 2.974c-.546 0-1.086-.032-1.615-.095a18.85 18.85 0 0010.378 3.116c12.455 0 19.263-10.577 19.263-19.752 0-.301-.005-.603-.017-.9 1.323-.979 2.471-2.2 3.376-3.592z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default TwitterIcon