const OrderListIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 58 58"
        >
            <path
                fill="#000"
                d="M18.476 17.45a3.08 3.08 0 11-6.159 0 3.08 3.08 0 016.159 0zM15.396 32.846a3.08 3.08 0 100-6.159 3.08 3.08 0 000 6.159zM18.476 42.084a3.08 3.08 0 11-6.159 0 3.08 3.08 0 016.159 0zM26.687 16.423a2.053 2.053 0 000 4.106H43.11a2.053 2.053 0 000-4.106H26.687zM24.634 30.793c0-1.134.92-2.053 2.053-2.053H43.11a2.053 2.053 0 010 4.106H26.687a2.053 2.053 0 01-2.053-2.053zM26.687 41.057a2.053 2.053 0 000 4.106H43.11a2.053 2.053 0 000-4.106H26.687z"
            ></path>
            <path
                fill="#000"
                d="M0 8.211A8.211 8.211 0 018.211 0H49.27a8.211 8.211 0 018.211 8.211V49.27a8.211 8.211 0 01-8.211 8.211H8.21A8.211 8.211 0 010 49.269V8.21zm8.211-4.105A4.106 4.106 0 004.106 8.21V49.27a4.106 4.106 0 004.105 4.105H49.27a4.106 4.106 0 004.105-4.105V8.21a4.106 4.106 0 00-4.105-4.105H8.21z"
            ></path>
        </svg>
    );
}

export default OrderListIcon;